export const COLORS =  {
    PURPLE: 'Purple',
    BLUE: 'Blue',
    ORANGE: 'Orange',
    GREEN: 'Green',
    WILD: 'Wild',
};

export const ITEMS = {
    FEATHER: 'Feather',
    DIE: 'Die',
    BUTTON: 'Button',
    MARBLE: 'Marble',
    SPOOL: 'Spool',
    WILD: 'Wild',
}

export function getTile(color, item) {
    return {
        color: color,
        item: item,
    }
}