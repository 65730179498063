import React from 'react';
import './Calculator.scss';
import {COLORS, getTile, ITEMS} from "./data";
import {motion} from 'framer-motion';

function TileButton(props) {
    return <motion.button
        onClick={()=>props.addTile(props.color, props.item)}
        className="tile"
        initial={{scale: 0}}
        animate={{scale: 1}}
        transition={{duration: .2, type: "spring"}}
    >
        <img class="tile-button-img" src={"/images/"+props.color+" "+props.item+".png"} alt={props.color+" "+props.item} />
        {props.delete ? <img className="minus" src="/images/Delete Icon.png" alt="delete" /> : null}
    </motion.button>
}

function getScore(tiles) {
    tiles = tiles.filter(t=>t !== null);
    let score = tiles.length;
    let color_count = {
        "Purple": 0,
        "Blue": 0,
        "Orange": 0,
        "Green": 0,
    };
    let item_count = {
        "Spool": 0,
        "Die": 0,
        "Feather": 0,
        "Marble": 0,
        "Button": 0,
    };
    let wild_count = 0;
    for(let one_tile of tiles) {
        if(one_tile.color===COLORS.WILD) {
            wild_count++;
        } else {
            color_count[one_tile.color]++;
            item_count[one_tile.item]++;
        }
    }
    score += wild_count*2;
    score += Math.max(...Object.values(color_count));
    score += Math.max(...Object.values(item_count));
    return score;
}

export default class Calculator extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tiles: [],
            showing_score: false,
        }
    }

    addTile(color, item) {
        let new_tiles = this.state.tiles.slice(0);
        new_tiles.push(getTile(color, item));
        this.setState({
            tiles: new_tiles,
            showing_score: false,
        });
    }

    removeTile(i) {
        let new_tiles = this.state.tiles.slice(0);
        new_tiles.splice(i, 1, null);
        this.setState({tiles: new_tiles});
    }

    render() {
        return <div className="calculator">
            <div className="display">
                <div className={"display-tiles"+(this.state.showing_score ? " faded" : "")}>
                    {this.state.tiles.map(
                        (v, i)=>v===null ? null : <TileButton
                            color={v.color}
                            item={v.item}
                            key={i+"_"+v.color+"_"+v.item}
                            delete={true}
                            addTile={(c,ii)=>this.removeTile(i)}
                        />
                    )}
                </div>
                { this.state.showing_score ?
                    <motion.div className="score" animate={{opacity: 1}} initial={{opacity: 0}} transition={{duration: .2}}>
                        <div className="score-number">{getScore(this.state.tiles)}</div>
                        <img src="/images/Berry.png" alt="points" />
                    </motion.div>
                : null }
            </div>
            <div className="buttons">
                <img src='/images/Gold Trim.png' alt="border" className="border" />
                <div className="top-buttons">
                    <button onClick={()=>this.setState({tiles: [], showing_score: false,})} className="restart">
                        <img src="/images/Refresh Button.png" alt="refresh" />
                    </button>
                    <button onClick={()=>this.addTile(COLORS.WILD, ITEMS.WILD)} className="tile wild">
                        <img src="/images/Wild Wild.png" alt="wild" />
                    </button>
                    <button onClick={()=>this.setState({showing_score: !this.state.showing_score})} className="score">
                        <img src="/images/Equals Button.png" alt="equals" />
                    </button>
                </div>
                <div className="tiles">
                    <TileButton addTile={(c,i)=>this.addTile(c,i)} color={COLORS.PURPLE} item={ITEMS.BUTTON} />
                    <TileButton addTile={(c,i)=>this.addTile(c,i)} color={COLORS.BLUE} item={ITEMS.BUTTON} />
                    <TileButton addTile={(c,i)=>this.addTile(c,i)} color={COLORS.ORANGE} item={ITEMS.BUTTON} />
                    <TileButton addTile={(c,i)=>this.addTile(c,i)} color={COLORS.GREEN} item={ITEMS.BUTTON} />

                    <TileButton addTile={(c,i)=>this.addTile(c,i)} color={COLORS.PURPLE} item={ITEMS.DIE} />
                    <TileButton addTile={(c,i)=>this.addTile(c,i)} color={COLORS.BLUE} item={ITEMS.DIE} />
                    <TileButton addTile={(c,i)=>this.addTile(c,i)} color={COLORS.ORANGE} item={ITEMS.DIE} />
                    <TileButton addTile={(c,i)=>this.addTile(c,i)} color={COLORS.GREEN} item={ITEMS.DIE} />

                    <TileButton addTile={(c,i)=>this.addTile(c,i)} color={COLORS.PURPLE} item={ITEMS.SPOOL} />
                    <TileButton addTile={(c,i)=>this.addTile(c,i)} color={COLORS.BLUE} item={ITEMS.SPOOL} />
                    <TileButton addTile={(c,i)=>this.addTile(c,i)} color={COLORS.ORANGE} item={ITEMS.SPOOL} />
                    <TileButton addTile={(c,i)=>this.addTile(c,i)} color={COLORS.GREEN} item={ITEMS.SPOOL} />

                    <TileButton addTile={(c,i)=>this.addTile(c,i)} color={COLORS.PURPLE} item={ITEMS.MARBLE} />
                    <TileButton addTile={(c,i)=>this.addTile(c,i)} color={COLORS.BLUE} item={ITEMS.MARBLE} />
                    <TileButton addTile={(c,i)=>this.addTile(c,i)} color={COLORS.ORANGE} item={ITEMS.MARBLE} />
                    <TileButton addTile={(c,i)=>this.addTile(c,i)} color={COLORS.GREEN} item={ITEMS.MARBLE} />

                    <TileButton addTile={(c,i)=>this.addTile(c,i)} color={COLORS.PURPLE} item={ITEMS.FEATHER} />
                    <TileButton addTile={(c,i)=>this.addTile(c,i)} color={COLORS.BLUE} item={ITEMS.FEATHER} />
                    <TileButton addTile={(c,i)=>this.addTile(c,i)} color={COLORS.ORANGE} item={ITEMS.FEATHER} />
                    <TileButton addTile={(c,i)=>this.addTile(c,i)} color={COLORS.GREEN} item={ITEMS.FEATHER} />
                </div>
            </div>
        </div>;
    }
}